<template>
    <div class="container">
      <ul class="nav">
        <li class="message" :class="{ 'active': isActive }" @click="isActive = true">随车资料</li>
        <li class="message" :class="{ 'active': !isActive }" @click="isActive = false">车型参数</li>
      </ul>
      <div class="box">
        <div class="content">
          <ul v-if="isActive">
            <li class="list" v-for="(item, index) in list.material" :key="index">
              <div>{{ item.name }}</div>
              <div class="chinren" v-for="(ite, ind) in item.children" :key="ind">
                <div class="chinrens">
                  <div>{{ ite.fullName }}</div>
                  <div>{{ ite.value }}</div>
                </div>
                <div v-if="ite.value == '有'">
                  <div class="haveInfo" v-for="(it, id) in ite.children" :key="id">
                    <div>{{ it.fullName }}</div>
                    <div>{{ it.value }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul v-if="!isActive" class="uls">
            <li class="list" v-for="(item, index) in list.params" :key="index">
              <div>{{ item.name }}</div>
              <div class="chinren" v-for="(ite, ind) in item.children" :key="ind">
                <div class="chinrens">
                  <div>{{ ite.fullName }}</div>
                  <div v-if="ite.value">{{ ite.value }}</div>
                  <div v-else>-</div>
                </div>
              </div>
            </li>
            <li class="sotr" v-if="params">
              <div>{{ params.children[0].name }}</div>
              <div>{{ params.children[0].value }}</div>
            </li>
          </ul>
        </div>
      </div>
      <img class="back_img" @click="call_back" src="../../assets/backs.png" alt="">
    </div>
  </template>
  <script>
  import { getVehicleDetail_s } from "@/utils/http.js";
  export default {
    name: "",
    components: {},
    props: [],
    data() {
      return {
        list: {},
        isActive: true,
        params: "",
      };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
      this.getMsg();
    },
    methods: {
      formatThousand(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      call_back() { this.$router.go(-1); },
      async getMsg() {
        const { data: res } = await getVehicleDetail_s({
          taskId: this.$route.query.id,
        });
        function objectSort(property) {
          return function (Obj1, Obj2) {
            return Obj1[property] - Obj2[property];
          };
        }
        res.content.params.sort(objectSort("sortId"));
        res.content.material.sort(objectSort("sortId"));
        res.content.params.forEach(item=>{
          item.children.forEach(ite=>{
            if(ite.valueType == 2){
              ite.value = this.formatThousand(ite.value)
              console.log(ite,'===')
            }
            if(ite.valueType == 3){
              ite.value = this.formatThousand(ite.value)
              console.log(ite,'===')
            }
          })
        })
        res.content.material.forEach((item) => {
          // console.log(item, '===')
          item.children.forEach((ite) => {
            if (ite.valueType == 6) {
              // console.log(ite, '--')
              let time = ite.value.split('-')
              ite.value = `${time[0]}年${time[1] - 0}月${time[2]-0}日`
            }
            if (ite.value == "true") {
              ite.value = "有";
              if (ite.children) {
                ite.children.forEach(it => {
                  if (it.valueType == 6) {
                    // console.log(it, '--')
                    let time = it.value.split('-')
                    it.value = `${time[0]}年${time[1] - 0}月${time[2]-0}日`
                  }
                })
              }
            }
            if (ite.value == "false") {
              ite.value = "无";
            }
          });
        });
        this.params = res.content.params.pop();
        console.log(this.params);
        this.list = res.content;
      },
    },
  };
  </script>
  <style scoped>
  .container {
    background: #f8f8f8;
    min-height: 100vh;
  }
  .nav {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    background: #ffffff;
    height: 60px;
    align-items: center;
  }
  .nav > li {
    width: 90px;
    margin-left: 15px;
  }
  .message {
    width: 90px;
    font-size: 16px;
    line-height: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    line-height: 36px;
    background: #ededed;
    border-radius: 5px;
    text-align: center;
  }
  .active {
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #0266e6;
    background: #e0eaf7;
  }
  .box {
    padding-top: 60px;
  }
  .content {
    margin-top: 10px;
  }
  .list {
    width: 100%;
    min-height: 150px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .list > div {
    border-top: 1px solid #ebebeb;
  }
  .list > div:nth-of-type(1) {
    border: none;
    line-height: 44px;
  }
  /* .chinren {
    display: flex;
    justify-content: space-between;
  } */
  
  .chinrens {
    min-height: 44px;
    display: flex;
    align-items: center;
  }
  .haveInfo {
    min-height: 38px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
  }
  .chinrens > div,
  .haveInfo > div {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    padding: 10px 0;
  }
  .chinrens > div:nth-of-type(1),
  .haveInfo > div:nth-of-type(1) {
    width: 124px;
  }
  
  .chinrens > div:nth-of-type(2),
  .haveInfo > div:nth-of-type(2) {
    flex: 1;
    font-weight: bold;
    word-break: break-all;
    word-wrap: break-word;
    margin-left: 14px;
  }
  .uls {
    padding-bottom: 20px;
  }
  .chinrens > div:nth-of-type(2) {
    margin-left: 24px;
  }
  .haveInfo {
    box-sizing: border-box;
    padding: 0 10px;
  }
  .haveInfo > div:nth-of-type(1) {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .haveInfo > div:nth-of-type(2) {
    font-size: 14px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
  }
  .sotr {
    width: 100%;
    min-height: 10px;
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .sotr > div:nth-of-type(1) {
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .sotr > div:nth-of-type(2) {
    font-size: 15px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    margin-top: 12px;
    padding-bottom: 10px;
  }
  .back_img{
    width: 40px;
    height: 40px;
    position: fixed;
    right: 10px;
    bottom: 150px;
    z-index: 99999999;
  }
  </style>